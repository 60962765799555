import React, { useState } from "react";
import marsipan from "../../resources/pontunarkokur/bjossi9.jpg";
import heiddukaka1 from "../../resources/pontunarkokur/heiddukaka6.jpg";
import afmæliskaka from "../../resources/pontunarkokur/bjossi8.jpg";
import bjossi4 from "../../resources/pontunarkokur/bjossi4.jpg";
import PontunModal from "../../components/pontun_modal";
import { NavigationLink } from "../../components/navigation_link";

export const Pantanir = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    console.log("openModal");
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const images = [
    afmæliskaka, heiddukaka1, marsipan
  ];

  return (
    <div className="flex flex-col justify-center items-center bg-cover bg-center min-h-screen p-5" style={{ backgroundImage: `url(${require('../../resources/pontunarkokur/kokur.jpg')})` }}>
      <div className="flex flex-col rounded-xl bg-black border-2 border-white w-11/12 text-center text-white text-xl font-serif md:flex-row justify-start pt-5">
      <div className="flex flex-col border-white items-center md:w-1/2 sm:w-2/3 p-2 sm:p-10 md:border-e-2 sm:border-e-0 border-b-2 md:border-b-0">
        <h1 className="text-2xl uppercase font-bold font-serif mb-0 md:text-3xl mt-10">Tertu pantanir</h1>
        <h2 className="text-xl uppercase font-light mt-2 mb-2 md:text-2xl">Athugið!</h2>
        <p className="text-lg font-light uppercase mt-2 mb-2 md:text-xl">Tveir virkir dagar eru í fyrirvara á öllum pöntunum hjá okkur!</p>
        <p className="text-lg font-light uppercase mt-2 md:text-xl">Pantanir fyrir helgar og mánudaga verða að berast fyrir <u>kl. 14 á fimmtudögum.</u></p>
        {/* <p className="font-light uppercase">Staðfestingarpóstur er alltaf sendur þegar pöntunin er móttekin.</p> */}
        <div className="flex justify-center items-center w-48 h-12 border border-white bg-opacity-100 bg-[#C29979] text-white mt-8 mb-4 text-lg font-serif hover:bg-opacity-30 hover:text-2xl">
          <NavigationLink title="PANTA HÉR" href="/pontun" />
        </div>
      </div>
        <div className="flex flex-col items-center md:w-1/2">
        <div className="flex flex-wrap items-center w-full">
            <img src={marsipan} alt="Marsipan" className="object-cover h-1/2 w-1/2 p-4"/>
            <img src={heiddukaka1} alt="Heiðrúnarkaka" className="object-cover h-1/2 w-1/2 p-4"/>
            <img src={afmæliskaka} alt="Afmæliskaka" className="object-cover h-1/2 w-1/2 p-4"/>
            <img src={bjossi4} alt="Afmæliskaka" className="object-cover h-1/2 w-1/2 p-4"/>
        </div>
        <div className="flex justify-center items-center w-48 h-12 pt-3 pb-3 border border-white bg-opacity-100 bg-[#C29979] text-white mt-5 text-lg font-serif mb-5 hover:bg-opacity-30 hover:text-2xl">
          <NavigationLink title="FLEIRI MYNDIR" href="/myndaalbum" />
        </div>
        </div>
      </div>
    </div>
  );
};

export default Pantanir;
