import React, { useState, useRef, useEffect } from 'react';
import jsondata from "../../resources/kokur.json";
import Modal from 'react-modal';
import axios from 'axios';
import CustomAlert from '../../components/alert';
import PontunAlert from '../../components/pontun_alert';
import './styles.css';
import { v4 as uuidv4 } from 'uuid'; 

const Pontun_modal = () => {
    const form = useRef();
    const [step, setStep] = useState(1);
    const [selection, setSelection] = useState('cake');
    const [cakes, setCakes] = useState([]);
    const [breads, setBreads] = useState([]);
    const [minidonuts, setMinidonuts] = useState([]);

    Modal.setAppElement('#root');

    const today = new Date();
    const futureDate = new Date(today);
    futureDate.setDate(today.getDate() + 2);

    // Cake State
    const [selectedCake, setSelectedCake] = useState('');
    const [selectedSize, setSelectedSize] = useState('');
    const [selectedFilling, setSelectedFilling] = useState('');
    const [selectedBottom, setSelectedBottom] = useState('');
    const [selectedSmjorkrem, setSelectedSmjorkrem] = useState('');

    // Bread State
    const [selectedBread, setSelectedBread] = useState('');
    const [selectedBreadQuantity, setSelectedBreadQuantity] = useState('');

    // Minidonuts State
    const [selectedMinidonutsQuantity, setSelectedMinidonutsQuantity] = useState('');
    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [pontun_alertIsOpen, setPontunAlertIsOpen] = useState(false);
    const [selectedName, setSelectedName] = useState('');
    const [selectedPhone, setSelectedPhone] = useState('');
    const [selectedEmail, setSelectedEmail] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [userMessage, setUserMessage] = useState('');
    const [selectedUrl, setSelectedUrl] = useState('');
    const [selectedText, setSelectedText] = useState('');
    const [selectedSkreyting, setSelectedSkreyting] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);

    const handleAddCake = () => {
        if (selectedCake === '') {
            setAlertMessage('Vinsamlegast veldu kökutegund');
            setAlertIsOpen(true);
            return;
        }
        if (selectedCake !== 'Sykurmassamynd' && selectedSize === '') {
            setAlertMessage('Vinsamlegast veldu stærð');
            setAlertIsOpen(true);
            return;
        }
        if (selectedCake === '1' && selectedFilling === '') {
            setAlertMessage('Vinsamlegast veldu fyllingu');
            setAlertIsOpen(true);
            return;
        }
        if (selectedCake === '2' && selectedBottom === '') {
            setAlertMessage('Vinsamlegast veldu botn');
            setAlertIsOpen(true);
            return;
        }

        const newCake = {
            cake: selectedCake,
            size: selectedSize,
            filling: selectedFilling,
            bottom: selectedBottom,
            smjorkrem: selectedSmjorkrem,
            url: selectedUrl,
            text: selectedText,
            skreyting: selectedSkreyting,
            image: selectedImage,
        };

        setCakes([...cakes, newCake]);

        // Reset cake selection fields
        setSelectedCake('');
        setSelectedSize('');
        setSelectedFilling('');
        setSelectedBottom('');
        setSelectedSmjorkrem('');
        setSelectedUrl('');
        setSelectedText('');
        setSelectedSkreyting('');
    };

    const handleAddBread = () => {
        if (selectedBread === '') {
            setAlertMessage('Vinsamlegast veldu brauðtegund');
            setAlertIsOpen(true);
            return;
        }
        if (selectedBreadQuantity === '') {
            setAlertMessage('Vinsamlegast veldu magn');
            setAlertIsOpen(true);
            return;
        }

        const newBread = {
            bread: selectedBread,
            quantity: selectedBreadQuantity,
        };

        setBreads([...breads, newBread]);

        // Reset bread selection fields
        setSelectedBread('');
        setSelectedBreadQuantity('');
    };

    const handleAddMinidonuts = () => {
        if (selectedMinidonutsQuantity === '') {
            setAlertMessage('Vinsamlegast veldu magn af minidonuts');
            return;
        }

        const newMinidonuts = {
            quantity: selectedMinidonutsQuantity,
        };

        setMinidonuts([...minidonuts, newMinidonuts]);

        // Reset minidonuts selection fields
        setSelectedMinidonutsQuantity('');
    };

    const handleNextStep = () => {
        if (cakes.length === 0 && breads.length === 0 && minidonuts.length === 0) {
            setAlertMessage('Vinsamlegast bættu við köku, brauði eða minidonuts');
            setAlertIsOpen(true);
            return;
        }
        setStep(2);
    };

    const handlePreviousStep = () => {
        setStep(1);
    };

    const handleNameChange = (event) => {
        setSelectedName(event.target.value);
    };

    const handlePhoneChange = (event) => {
        setSelectedPhone(event.target.value);
    };

    const handleEmailChange = (event) => {
        setSelectedEmail(event.target.value);
    };

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const handleSizeChange = (event) => {
        setSelectedSize(event.target.value);
    };

    const handleFillingChange = (event) => {
        setSelectedFilling(event.target.value);
    };

    const handleBottomChange = (event) => {
        setSelectedBottom(event.target.value);
    };

    const handleCakeChange = (event) => {
        setSelectedCake(event.target.value);
    };

    const handleBreadChange = (event) => {
        setSelectedBread(event.target.value);
    };

    const handleBreadQuantityChange = (event) => {
        setSelectedBreadQuantity(event.target.value);
    };

    const handleMinidonutsQuantityChange = (event) => {
        setSelectedMinidonutsQuantity(event.target.value);
    };

    const handleSmjorkremChange = (event) => {
        setSelectedSmjorkrem(event.target.value);
    };

    const handleUrlChange = (event) => {
        setSelectedUrl(event.target.value);
    };

    const handleTextChange = (event) => {
        setSelectedText(event.target.value);
    };

    const handleSkreytingChange = (event) => {
        setSelectedSkreyting(event.target.value);
    };

    const handleImageChange = (event) => {
        setSelectedImage(event.target.files[0]);
    };

    const sendOrderEmail = async (email) => {
        try {
          // Construct the HTML content for the email dynamically
            let productList = '';
            cakes.forEach((cake) => {
                if (cake.cake === 'Sykurmassamynd') {
                    productList += `<li>Tegund:${cake.cake}</li>`;
                }
                else if (cake.cake === 'Afmælistertur') {
                    productList += `<li>Tegund:${cake.cake} - Stærð:${cake.size} - Texti:${cake.text}</li>`;
                }
                else if (cake.cake === 'Marsipantertur' || cake.cake === 'Skírnarkoddar' || cake.cake === 'Fermingarbækur') {
                    productList += `<li>Tegund:${cake.cake} - Fylling:${cake.filling} - Stærð:${cake.size} - Texti:${cake.text} - Skreyting:${cake.skreyting}</li>`;
                }
                else if (cake.cake === 'Smjörkremskaka') {
                    productList += `<li>Tegund:${cake.cake} - Stærð:${cake.size} - Botn:${cake.bottom} -  Smjörkrem:${cake.smjorkrem}</li>`;
                }
                else {
                    productList += `<li>Tegund:${cake.cake} - Stærð:${cake.size}</li>`;
                }
            });
            breads.forEach((bread) => {
                productList += `<li>Tegund:${bread.bread} - Magn:${bread.quantity}</li>`;
            });
            minidonuts.forEach((donut) => {
                productList += `<li>Minidonuts - Magn:${donut.quantity}</li>`;
            });
        

      
          const emailContent = `
            <p>Hæ ${selectedName},</p>
            <p>Takk fyrir að panta í gegnum vefsíðuna okkar!</p>
            <p><strong>Dagsetning:</strong> ${selectedDate}</p>
            <p><strong>Pöntun:</strong></p>
            <ul>${productList}</ul>
            <p><strong>Athugasemd:</strong> ${userMessage}</p>
            <p>Kveðja,</p>
            <p>Kallabakarí</p>
          `;
      
          const response = await fetch('https://api.kallabakari.is/api/send-order-email', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              to: email,
              name: selectedName,
              date: selectedDate,
              products: [
                ...cakes,
                ...breads,
                ...minidonuts,
              ],
              subject: 'Staðfesting á pöntun', // Confirmation of order
              text: 'Takk fyrir að panta í gegnum vefsíðuna okkar!', // Thank you for ordering from our website!
              html: emailContent,
            }),
          });
      
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
      
          const result = await response.json();
          console.log('Email sent successfully:', result);
        } catch (error) {
          console.error('Error sending email:', error);
        }
      };
      
      

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (selectedName === '') {
            setAlertMessage('Vinsamlegast fylltu út nafn');
            setAlertIsOpen(true);
            return;
        }
        if (selectedPhone === '') {
            setAlertMessage('Vinsamlegast fylltu út símanúmer');
            setAlertIsOpen(true);
            return;
        }
        if (selectedPhone.length !== 7) {
            setAlertMessage('Símanúmer verður að vera 7 tölustafir');
            setAlertIsOpen(true);
            return;
        }
        if (selectedEmail === '') {
            setAlertMessage('Vinsamlegast fylltu út netfang');
            setAlertIsOpen(true);
            return;
        }
        if (selectedEmail.indexOf('@') === -1) {
            setAlertMessage('Netfang verður að innihalda @');
            setAlertIsOpen(true);
            return;
        }
        if (selectedDate === '') {
            setAlertMessage('Vinsamlegast veldu dagsetningu');
            setAlertIsOpen(true);
            return;
        }
        console.log(selectedImage);
        const today = new Date();
        const futureDate = new Date(today);
        futureDate.setDate(today.getDate() + 3);
        if (selectedDate < futureDate.toISOString().split('T')[0]) {
            setAlertMessage('Pöntun verður berast með tveggja daga fyrirvara');
            setAlertIsOpen(true);
            return;
        }
    

        const prepareProducts = (items, type) => {
            return items.map(item => ({
                type: type,
                details: item,
            }));
        };

        const formData = new FormData();
        console.log('data:', formData);
        formData.append('id', uuidv4());
        formData.append('name', selectedName);
        formData.append('phone', selectedPhone);
        formData.append('email', selectedEmail);
        formData.append('date', selectedDate);
        formData.append('products', JSON.stringify([
            ...prepareProducts(cakes, 'cake'),
            ...prepareProducts(breads, 'bread'),
            ...prepareProducts(minidonuts, 'minidonut'),
        ]));
        formData.append('user_message', userMessage);
        formData.append('payed', false);
        formData.append('image', selectedImage);
        
        try {
            await axios.post('https://api.kallabakari.is/api/orders', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            sendOrderEmail(selectedEmail);
            form.current.reset();
            setStep(1); // Reset to the first step
            setCakes([]); // Clear the cakes
            setBreads([]); // Clear the breads
            setMinidonuts([]); // Clear the minidonuts
            setSelectedName('');
            setSelectedPhone('');
            setSelectedEmail('');
            setSelectedDate('');
            setUserMessage('');
            setSelectedUrl('');
            setSelectedText('');
            setSelectedSkreyting('');
            setSelectedImage(null);
            setPontunAlertIsOpen(true);
        } catch (error) {
            console.error('Error posting order:', error);
            setAlertMessage('Villa kom upp við að skrá pöntun');
            setAlertIsOpen(true);
        }
    }

    return (
        <div className="flex flex-col justify-center items-center bg-cover bg-center min-h-screen"
        style={{backgroundImage: `url(${require('../../resources/pontunarkokur/kokur.jpg')})`}} >
            <CustomAlert
                isOpen={alertIsOpen}
                onClose={() => setAlertIsOpen(false)}
                message={alertMessage}
            />
            <PontunAlert
                isOpen={pontun_alertIsOpen}
                onClose={() => setPontunAlertIsOpen(false)}
            />
            <div className="flex flex-col w-4/5 h-full mx-4 my-2 p-10 ml-20 mr-20 mt-20 mb-20 bg-black md:flex-row">
                <div className="w-full sm:border-r-0 md:w-2/3 md:border-r">
                    <div className="flex flex-col items-center w-full">
                        <h1 className="text-white font-serif text-2xl font-light uppercase mx-auto">
                            {step === 1 ? 'Veldu Tegund' : 'Upplýsingarnar þínar'}
                        </h1>
                    </div>
                    {step === 1 && (
                        <>
                            <div className="flex flex-row items-center w-[95%] mt-4 border-b mr-1">
                                <button onClick={() => setSelection('cake')} className={`flex flex-col items-center xl:w-2/5 h-32 mb-4 text-white ${selection === 'cake' ? 'bg-[rgba(194,153,121,1)]' : 'bg-[rgba(194,153,121,0.5)]'} border border-white font-cinzel text-xl uppercase sm: w-4/5 sm:text-lg`}>
                                    <span className="h-[20%] font-serif text-lg p-0.55">Kökur</span>
                                    <img src={require('../../resources/pontunarkokur/heidrunkaka2.jpeg')} alt="Kokur" className="w-full h-[80%] object-cover"/>
                                </button>
                                <button onClick={() => setSelection('bread')} className={`flex flex-col items-center xl:w-2/5 h-32 mb-4 text-white ${selection === 'bread' ? 'bg-[rgba(194,153,121,1)]' : 'bg-[rgba(194,153,121,0.5)]'} border border-white font-cinzel text-xl uppercase sm: w-4/5 sm:text-lg`}>
                                    <span className="h-[20%] font-serif text-lg p-0.55">Brauð</span>
                                    <img src={require('../../resources/braud/snittubraud.JPG')} alt="Kokur" className="w-full h-[80%] object-cover"/>
                                </button>
                                <button onClick={() => setSelection('minidonuts')} className={`flex flex-col items-center xl:w-2/5 h-32 mb-4 text-white ${selection === 'minidonuts' ? 'bg-[rgba(194,153,121,1)]' : 'bg-[rgba(194,153,121,0.5)]'} border border-white font-cinzel text-xl uppercase sm: w-4/5 sm:text-lg`}>
                                    <span className="h-[20%] font-serif text-lg p-0.55">Mini-donuts</span>
                                    <img src={require('../../resources/bakkelsi/dontusmynd.JPG')} alt="Kokur" className="w-full h-[80%] object-cover"/>
                                </button>
                            </div>
                            {selection === 'cake' && (
                                <form className="flex flex-col items-center mb-4" ref={form}>
                                    <div className="flex flex-col w-full items-center mb-4">
                                        <h2 className="text-white font-serif text-2xl uppercase mt-3">Kökur</h2>
                                        <div className="flex flex-col w-4/5 mb-2">
                                            <label className="text-white font-serif text-xl uppercase mt-2" htmlFor="cake">Tegund:</label>
                                            <select className="w-full h-12 mb-2 border-none font-serif text-lg sm:text-md w-4/5" id="cake" name="cake_type" onChange={handleCakeChange} value={selectedCake} required>
                                                <option value="">VELDU TEGUND</option>
                                                {jsondata.kokur.map((item) => (
                                                    <option key={item.id} value={item.name}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {selectedCake && (
                                            <>
                                            {selectedCake !== 'Sykurmassamynd' && (
                                                <div className="flex flex-col w-4/5 mb-2">
                                                    <label className="text-white font-serif text-xl uppercase" htmlFor="size">Stærð:</label>
                                                    <select className="w-full h-12 mb-2 border-none font-serif text-lg sm: w-4/5" id="size" name="cake_size" onChange={handleSizeChange} value={selectedSize} required>
                                                        <option value="">Veldu stærð</option>
                                                        {jsondata.kokur.find(item => item.name === selectedCake).size.map((sizeItem) => (
                                                            <option key={sizeItem.size} value={sizeItem.size}>
                                                                {sizeItem.size} - {sizeItem.price.toFixed(3)} kr.
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                )}
                                                {jsondata.kokur.find(item => item.name === selectedCake).fillings && (
                                                    <div className="flex flex-col w-4/5 mb-2">
                                                        <label className="text-white font-serif text-xl uppercase" htmlFor="filling">Fylling</label>
                                                        <select className="w-full h-12 mb-2 border-none font-serif text-lg sm: w-4/5" id="filling" name="filling" onChange={handleFillingChange} value={selectedFilling}>
                                                            <option value="">Veldu fyllingu</option>
                                                            {jsondata.kokur.find(item => item.name === selectedCake).fillings.map((filling) => (
                                                                <option key={filling} value={filling}>
                                                                    {filling}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                )}
                                                {jsondata.kokur.find(item => item.name === selectedCake).botnar && (
                                                    <div className="flex flex-col w-4/5 mb-2">
                                                        <label className="text-white font-serif text-lg uppercase" htmlFor="bottom">Botn:</label>
                                                        <select className="w-full h-12 mb-2 border-none font-serif text-lg sm: w-4/5" id="bottom" name="bottom" onChange={handleBottomChange} value={selectedBottom}>
                                                            <option value="">Veldu botn</option>
                                                            {jsondata.kokur.find(item => item.name === selectedCake).botnar.map((botn) => (
                                                                <option key={botn} value={botn}>
                                                                    {botn}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                )}
                                                {jsondata.kokur.find(item => item.name === selectedCake).smjorkrem && (
                                                    <div className="flex flex-col w-4/5 mb-2">
                                                        <label className="text-white font-serif text-lg uppercase" htmlFor="smjorkrem">Smjörkrem:</label>
                                                        <select className="w-full h-12 mb-2 border-none font-serif text-lg sm: w-4/5" id="smjorkrem" name="smjorkrem" onChange={handleSmjorkremChange} value={selectedSmjorkrem}>
                                                            <option value="">Veldu smjörkrem</option>
                                                            {jsondata.kokur.find(item => item.name === selectedCake).smjorkrem.map((smjorkrem) => (
                                                                <option key={smjorkrem} value={smjorkrem}>
                                                                    {smjorkrem}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                )}
                                                {(selectedCake === 'Afmælistertur') && (
                                                    <>
                                                        <div className="flex flex-col w-4/5 mb-2">
                                                            <label className="text-white font-serif text-lg uppercase" htmlFor="texti">Texti á köku:</label>
                                                            <input 
                                                                type="text" 
                                                                className="w-full h-12 mb-2 p-1 border-none font-serif text-lg placeholder-black" 
                                                                id="texti" 
                                                                name="texti" 
                                                                onChange={handleTextChange} 
                                                                value={selectedText}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                                {(selectedCake === 'Marsipantertur' || selectedCake === 'Smjörkremskaka' || selectedCake === 'Skírnarkoddar' || selectedCake === 'Fermingarbækur') && (
                                                    <>
                                                        <div className="flex flex-col w-4/5 mb-2">
                                                            <label className="text-white font-serif text-lg uppercase" htmlFor="texti">Texti á köku:</label>
                                                            <input 
                                                                type="text" 
                                                                className="w-full h-12 mb-2 p-1 border-none font-serif text-lg placeholder-black" 
                                                                id="texti" 
                                                                name="texti" 
                                                                onChange={handleTextChange} 
                                                                value={selectedText}
                                                            />
                                                        </div>
                                                        <div className="flex flex-col w-4/5 mb-2">
                                                            <label className="text-white font-serif text-lg uppercase" htmlFor="skreyting">Skreyting:</label>
                                                            <input 
                                                                type="skreyting" 
                                                                className="w-full h-12 mb-2 p-1 border-none font-serif text-lg placeholder-black" 
                                                                id="skreyting" 
                                                                name="skreyting" 
                                                                onChange={handleSkreytingChange}
                                                                value={selectedSkreyting}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                                {(selectedCake === 'Sykurmassamynd' || selectedCake === 'Afmælistertur með mynd') && (
                                                    <div className="flex flex-col w-4/5 mb-2">
                                                        <label className="text-white font-serif text-lg uppercase" htmlFor="cakeImage">Mynd á köku:</label>
                                                        <input
                                                            type="file"
                                                            className="w-full h-12 mb-2 p-1 border-none bg-white font-serif text-lg placeholder-black"
                                                            id="cakeImage"
                                                            name="cakeImage"
                                                            accept="image/*"
                                                            onChange={handleImageChange}
                                                        />
                                                    </div>
                                                )}

                                            </>
                                        )}
                                        <div className="flex w-full justify-center items-center mt-3">
                                            <button type="button" className="w-3/5 h-12 border-none rounded-none mb-2 text-white bg-[rgba(194,153,121,1)] border border-white font-serif text-lg uppercase font-light sm: w-4/5" onClick={handleAddCake}>Bæta við köku</button>
                                        </div>
                                    </div>
                                </form>
                            )}

                            {selection === 'bread' && (
                                <form className="flex flex-col items-center mb-4" ref={form}>
                                        <div className="flex flex-col items-center w-full mb-2">
                                            <h2 className="text-white font-serif text-2xl uppercase mt-3">Brauð</h2>
                                            <div className="flex flex-col w-4/5 mb-2">
                                            <label className="text-white font-cinzel text-lg uppercase" htmlFor="bread">Brauðtegund</label>
                                            <select className="w-full h-12 mb-2 border-none font-cinzel text-lg sm: w-4/5" id="bread" name="bread_type" onChange={handleBreadChange} value={selectedBread}>
                                                <option value="">VELDU TEGUND</option>
                                                <option value="Ólífubrauð">Ólífubrauð</option>
                                                <option value="Snittubrauð">Snittubrauð</option>
                                            </select>
                                            </div>
                                            <div className="flex flex-col w-4/5 mb-2">
                                            <label className="text-white font-cinzel text-lg uppercase" htmlFor="bread_quantity">Magn</label>
                                            <select className="w-full h-12 mb-2 border-none font-cinzel text-lg sm: w-4/5" id="bread_quantity" name="bread_quantity" onChange={handleBreadQuantityChange} value={selectedBreadQuantity}>
                                                <option value="">VELDU MAGN</option>
                                                {/* Add bread options here */}
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                            </div>
                                        </div>
                                        <div className="flex w-full justify-center items-center mt-3">
                                            <button type="button" className="w-3/5 h-12 border-none rounded-none mb-2 text-white bg-[rgba(194,153,121,1)] border border-white font-serif text-lg uppercase font-light sm: w-4/5" onClick={handleAddBread}>Bæta við brauði</button>
                                        </div>
                                </form>
                            )}

                            {selection === 'minidonuts' && (
                                <form className="flex flex-wrap mb-4" ref={form}>
                                    <div className="flex w-full mb-4 items-center flex-col">
                                        <h2 className="text-white font-serif text-2xl uppercase mt-2">Minidonuts</h2>
                                        <div className="flex flex-col w-4/5 mb-2">
                                            <label className="text-white font-serif text-lg uppercase mt-2" htmlFor="minidonuts_quantity">Magn</label>
                                            <input type="number" className="w-full h-12 p-1 mb-2 border-none font-cinzel text-lg sm: w-4/5" id="minidonuts_quantity" name="minidonuts_quantity" onChange={handleMinidonutsQuantityChange} value={selectedMinidonutsQuantity} />
                                        </div>
                                        <div className="flex w-full justify-center items-center mt-3">
                                            <button type="button" className="w-3/5 h-14 border-none rounded-none mb-2 text-white bg-[rgba(194,153,121,1)] border border-white font-serif text-lg uppercase font-light sm: w-4/5" onClick={handleAddMinidonuts}>Bæta við minidonuts</button>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </>
                    )}
                    {step === 2 && (
                        <form className="flex flex-wrap" onSubmit={handleSubmit} ref={form}>
                            <div className="flex flex-wrap w-full">
                                <div className="flex flex-col w-[95%] m-2 md:w-[45%]">
                                    <label className="text-white font-serif text-lg uppercase" htmlFor="name">Nafn</label>
                                    <input type="text" className="w-full h-12 mb-2 border-none rounded-none" id="name" name="name" onChange={handleNameChange} required />
                                </div>
                                <div className="flex flex-col w-[95%] m-2 md:w-[45%]">
                                    <label className="text-white font-cinzel text-lg uppercase" htmlFor="phone">Símanúmer</label>
                                    <input type="text" className="w-full h-12 mb-2 border-none rounded-none" id="phone" name="phone" onChange={handlePhoneChange} required />
                                </div>
                                <div className="flex flex-col w-[95%] m-2 md:w-[45%]">
                                    <label className="text-white font-cinzel text-lg uppercase" htmlFor="email">Netfang</label>
                                    <input type="email" className="w-full h-12 mb-2 border-none rounded-none" id="email" name="email" onChange={handleEmailChange} required />
                                </div>
                                <div className="flex flex-col w-[95%] m-2 md:w-[45%]">
                                    <label className="text-white font-cinzel text-lg uppercase" htmlFor="date">Dagsetning</label>
                                    <input type="date" className="w-full h-12 mb-2 border-none rounded-none" id="date" name="date" onChange={handleDateChange} min={futureDate} required />
                                </div>
                            </div>
                            <div className="flex flex-col w-full mb-2 m-2 md:w-[45%]">
                                <label className="text-white font-cinzel text-lg uppercase" htmlFor="Athugasemdir">Auka Athugasemdir: </label>
                                <textarea className="w-full h-24 bg-white border-none mb-2 p-2" id="Athugasemdir" name="user_message" rows="4" cols="50" onChange={(e) => setUserMessage(e.target.value)}></textarea>
                            </div>
                            <div className="flex w-full justify-center items-center mt-3 sm: flex-col ">
                                <button type="button" className="w-2/5 h-12 border-none rounded-none mb-2 font-serif text-white bg-[rgba(194,153,121,1)] border border-white font-cinzel text-lg uppercase font-light sm: w-4/5" onClick={handlePreviousStep}>Til Baka</button>
                                <input type="submit" className="w-2/5 h-12 border-none rounded-none mb-2 font-serif text-white bg-[rgba(194,153,121,1)] border border-white font-cinzel text-lg uppercase font-light sm: w-4/5" value="leggja inn pöntun" />
                            </div>
                        </form>
                    )}
                </div>
                <div className="flex flex-col items-center w-4/5 mx-4 md:w-1/3">
                    <h2 className="text-white font-serif text-lg uppercase mb-2">Yfirlit Pöntunar</h2>
                    {cakes.length > 0 || breads.length > 0 || minidonuts.length > 0 ? (
                        <div className="flex flex-col space-y-4">
                            {cakes.map((cake, index) => (
                                <div key={index} className="bg-[rgba(194,153,121,1)] p-3 border border-white rounded">
                                    <h3 className="font-bold font-serif text-white text-lg">{cake.cake}</h3>
                                    {cake.size && <p className="font-serif text-white">Stærð: {cake.size}</p>}
                                    {cake.filling && <p className="font-serif text-white">Fylling: {cake.filling}</p>}
                                    {cake.bottom && <p className="font-serif text-white">Botn: {cake.bottom}</p>}
                                    {cake.smjorkrem && <p className="font-serif text-white">Smjörkrem: {cake.smjorkrem}</p>}
                                    {cake.text && <p className="font-serif text-white">Texti á köku: {cake.text}</p>}
                                    {cake.skreyting && <p className="font-serif text-white">Skreyting: {cake.skreyting}</p>}
                                    {cake.url && <p className="font-serif text-white">Mynd: {cake.url}</p>}
                                    {cake.image && <img src={URL.createObjectURL(cake.image)} alt="cake" className="w-24 h-24 object-cover object-center" />}
                                </div>
                            ))}
                            {breads.map((bread, index) => (
                                <div key={index} className="bg-[rgba(194,153,121,1)] p-3 border border-white rounded">
                                    <h3 className="font-bold font-serif text-white text-lg">Brauð</h3>
                                    <p className="font-serif text-white">Brauð: {bread.bread} - Magn: {bread.quantity}</p>
                                </div>
                            ))}
                            {minidonuts.map((donut, index) => (
                                <div key={index} className="bg-[rgba(194,153,121,1)] p-3 border border-white rounded">
                                    <h3 className="font-bold font-serif text-white text-lg">Minidonuts</h3>
                                    <p className="font-serif text-white">Magn: {donut.quantity}</p>
                                </div>
                            ))}
                            {step === 1 && (
                            <div className="flex flex-col items-center w-full">
                                <button className="w-full h-12 border-none rounded-none mb-2 text-white bg-[rgba(194,153,121,1)] border border-white font-serif text-md uppercase font-light" onClick={handleNextStep}>Klára pöntun</button>
                            </div>
                            )}
                        </div>
                    ) : (
                        <p className="text-white text-lg font-serif">Engin vara valin ennþá...</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Pontun_modal;
