import React from "react";
import "./styles.css";
import PropTypes from "prop-types";

export const Vara = ({ name, image, description }) => {

  return (
    <div className="flex flex-col w-[75%] md:flex-row bg-black rounded-lg shadow-md overflow-hidden m-3 md:w-[45%]">
      <div className="md:flex-shrink-0">
        <img src={image} alt={name} className="object-cover w-full h-48 md:w-48" />
      </div>
      <div className="p-4">
        <h3 className="block mt-1 text-2xl leading-tight font-serif text-white">{name}</h3>
        <p className="mt-2  text-lg text-white font-serif">{description}</p>
      </div>
    </div>
  );
}

export default Vara;

Vara.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};