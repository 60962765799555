import React from 'react';
import braudlist from '../../resources/braud.json';
import { Vara } from '../../components/vara';
import './styles.css';

const Braud = () => {

  const getImagePath = (imageName) => {
    return require(`../../resources/braud/${imageName}`);
  };

  const url = require('../../resources/Coverbraud.JPG');

  return (
    <div className="mx-auto px-4 py-8 bg-cover bg-center bg-no-repeat bg-cover bg-fixed"
    style={{backgroundImage: `url(${url})`}}>
      <div className="text-center py-8 bg-black m-3 rounded-lg p-3">
        <h1 className="text-3xl font-bold text-white mb-4 font-serif">Brauð</h1>
        <p className="text-xl text-white font-serif">Brauðin okkar eru öll bökuð ný alla morgna</p>
      </div>
      <ul className="flex flex-wrap justify-center">
        {braudlist.items.map((braud) => (
          <Vara
            key={braud.id}
            name={braud.name}
            image={getImagePath(braud.images)}
            description={braud.description}
          />
        ))}
      </ul>
    </div>
  );
};

export default Braud;
