import React, { useState } from 'react';
import './styles.css';
import { NavigationLink } from '../navigation_link/index.jsx';
import { NavigationLink2 } from '../navigation_link2/index.jsx';
import coverlogo from '../../resources/coverlogo.png';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


export const NavigationBar = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSubMenuExpanded, setIsSubMenuExpanded] = useState(false);
  
  const toggleNavigation = () => {
    setIsExpanded(!isExpanded);
  };


  const toggleSubMenu = () => {
    setIsSubMenuExpanded(!isSubMenuExpanded);
  };

  return (
    <nav className={`navigation-bar ${isExpanded ? 'expanded' : ''} ${isSubMenuExpanded ? 'subexpanded' : ''}`}>
      <div className={`topbar ${isExpanded ? 'expanded' : ''}`}>
        <div className={`navigation-logo ${isExpanded ? 'expanded' : ''}`}>
          <img src={coverlogo} alt="coverlogo" onClick={() => window.location.href = '/'}/>
        </div>
        <button className="navigation-button" onClick={toggleNavigation}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
              <path d="M4 5L20 5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M4 12L20 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M4 19L20 19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button>
      </div>
      <div className={`navigation-links ${isExpanded ? 'expanded' : ''}`}>
        <NavigationLink title="Forsíða" href="/"/>
        {!isExpanded ? (
          <div className="navigation-menu">
            <button className={`navigation-menu__button`}>Vörur<ArrowDropDownIcon/></button>
            <ul className="navigation-menu__content">
              <NavigationLink2 title="Brauð" href="/braud"/>
              <NavigationLink2 title="Rúnstykki" href="/runstykki"/>
              <NavigationLink2 title="Bakkelsi" href="/bakkelsi"/>
              <NavigationLink2 title="Smurt" href="/smurt"/>
            </ul>
          </div>
        ) : (
          <div className={`navigation-menu2 ${isSubMenuExpanded ? 'subexpanded' : ''}`}>
            <button className={`navigation-menu__button2 expanded`} onClick={toggleSubMenu}>Vörur<ArrowDropDownIcon/></button>
            <ul className={`navigation-menu__content2 ${isSubMenuExpanded ? 'subexpanded' : ''}`}>
              <NavigationLink title=" - Brauð" href="/braud"/>
              <NavigationLink title=" - Rúnstykki" href="/runstykki"/>
              <NavigationLink title=" - Bakkelsi" href="/bakkelsi"/>
              <NavigationLink title=" - Smurt" href="/smurt"/>
            </ul>
          </div>
        )}
        <NavigationLink title="Kökur" href="/kokur" />
        <NavigationLink title="Panta" href="/pantanir"/>
        <NavigationLink title="Hafa samband" href="/hafa-samband"/>
        <NavigationLink title="Um okkur" href="/umokkur"/>
      </div>
    </nav>
  );
};

export default NavigationBar;

