import React from 'react';
import { Vara } from '../../components/vara';
import runstykkilist from '../../resources/runstykki.json';
import './styles.css';

const Runstykki= () => {

  const getImagePath = (imageName) => {
    return require(`../../resources/runstykki/${imageName}`);
  };

  const url = require('../../resources/runstykki.cover.jpeg');

  return (
    <div className="mx-auto px-4 py-8 bg-cover bg-center bg-no-repeat bg-cover bg-fixed"
    style={{backgroundImage: `url(${url})`}}>
      <div className="text-center mb-8 bg-black m-3 rounded-lg p-3">
        <h1 className="text-3xl font-bold text-white font-serif">Rúnstykki</h1>
        <p className="text-xl mt-4 text-white font-serif">Rúnstykkin okkar eru öll bökuð ný alla morgna</p>
      </div>
      <ul className="flex flex-wrap justify-center">
        {runstykkilist.items.map((item) => (
          <Vara
            key={item.id}
            name={item.name}
            image={getImagePath(item.images)}
            description={item.description}
          />
        ))}
      </ul>
    </div>
  );
};

export default Runstykki;
