import React from "react";
import { NavigationLink } from "../../components/navigation_link";
import Súpuplan from "../../components/súpuplan";
import { SalotMenu } from "../../components/salot_menu";
import url from '../../resources/Frontpage2.jpg';
import url2 from '../../resources/pontunarkokur/kokur.jpg';
import './styles.css';

const Home = () => {
    return (
        <div className="flex flex-col">
            <div
                className="flex flex-col w-full min-h-screen bg-cover bg-center bg-no-repeat bg-fixed justify-center items-center relative p-2"
                style={{ backgroundImage: `url(${url})` }}
            >
                <div className="flex flex-col items-center w-[80%] sm:w-[90%] md:w-[80%] lg:w-[70%] xl:w-[60%] 2xl:w-[60%] bg-black/70 p-4 sm:p-5 mt-5 mb-5 md:p-6">
                    <h2 className="text-4xl md:text-4xl lg:text-5xl text-white font-serif mb-2">KALLABAKARÍ</h2>
                    <p className="text-2xl sm:text-2xl md:text-3xl text-white font-serif mb-4">Velkomin á vefsíðuna okkar</p>
                    <p className="text-xl sm:text-xl md:text-2xl text-white font-serif">
                        Kallabakarí er handverksbakarí þar sem allt er gert frá grunni. Okkar áherslur eru mikið úrval af gæðavörum þar sem blandað er saman gömlu
                        góðu bakkelsi og ferskum nýjungum. Hjá okkur er gott að setjast niður í björtu og fallegu umhverfi þar sem allir geta fundið sér eitthvað við hæfi.
                    </p>
                </div>
            </div>
            <Súpuplan />
            <div
                className="flex flex-col h-96 w-full bg-cover bg-center bg-no-repeat bg-fixed relative items-center justify-center"
                style={{ backgroundImage: `url(${url2})` }}
            >
                <div className="flex flex-col items-center m-4 sm:m-8 md:m-20">
                    <h2 className="text-3xl sm:text-4xl md:text-5xl text-white font-serif mb-2">Veitingar fyrir öll tilefni</h2>
                    <p className="text-md sm:text-xl md:text-2xl text-white font-serif m-2">Afmæli - Brúðkaup - Erfidrykkjur - Fermingarveislur - Nafnaveislur - Útskriftarveislur</p>
                    <div className="flex border border-white rounded-lg h-8 w-16 items-center justify-center m-2 bg-[#C29979] p-3">
                        <NavigationLink title="Panta" href="/pantanir" />
                    </div>
                </div>
            </div>
            <SalotMenu />
        </div>
    );
};

export default Home;
