import React from "react";
import instagram from "../../resources/instagram.png";
import facebook from "../../resources/facebook.png";

export const Footer = () => {
    return (
        <div className="flex flex-col md:flex-row justify-between items-center w-full bg-black py-4 px-4">
            <div className="flex flex-col md:w-1/3 items-center mb-8 md:mb-0 md:items-center">
                <p className="font-serif text-xl md:text-2xl text-white text-center md:text-left mb-4">FYLGDU OKKUR:</p>
                <div className="flex">
                    <a href="https://www.facebook.com/kallabakari" target="_blank" rel="noopener noreferrer" className="m-2">
                        <img src={facebook} alt="facebook" className="w-10 h-10 md:w-16 md:h-16 rounded-full bg-white p-1" />
                    </a>
                    <a href="https://www.instagram.com/kallabakari" target="_blank" rel="noopener noreferrer" className="m-2">
                        <img src={instagram} alt="instagram" className="w-10 h-10 md:w-16 md:h-16 rounded-full bg-white p-1" />
                    </a>
                </div>
            </div>
            <div className="flex flex-col md:w-1/3 items-center mb-5 md:mb-0">
                <h1 className="font-serif text-xl md:text-2xl text-white mb-2">OPNUNARTÍMAR</h1>
                <div className="text-center">
                    <p className="font-serif text-base md:text-lg text-white mb-1">Mánudaga - Föstudaga: 07:00 - 17:00</p>
                    <p className="font-serif text-base md:text-lg text-white mb-1">Laugardaga: 08:00 - 16:00</p>
                    <p className="font-serif text-base md:text-lg text-white">Sunnudaga: 09:00 - 16:00</p>
                </div>
            </div>
            <div className="flex flex-col md:w-1/3 items-center mb-5 md:mb-0">
                <h1 className="font-serif text-xl md:text-2xl text-white mb-2">HAFÐU SAMBAND</h1>
                <div className="text-center md:text-left">
                    <p className="font-serif text-base md:text-lg text-white">Símanúmer: 431-1644</p>
                    <p className="font-serif text-base md:text-lg text-white">Netfang: kallabakari67@gmail.com</p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
