import React from 'react';
import { Vara } from '../../components/vara';
import Kokulist from '../../resources/kokur2.json';
import './styles.css';

const Kokur = () => {

  const getImagePath = (imageName) => {
    return require(`../../resources/kokur/${imageName}`);
  };

  const url = require('../../resources/ricecrispieskokur.JPG');

  return (
    <div className="mx-auto px-4 py-8 bg-cover bg-center bg-no-repeat bg-cover bg-fixed"
    style={{backgroundImage: `url(${url})`}}>
      <div className="text-center py-8 bg-black m-3 rounded-lg p-3">
        <h1 className="text-3xl font-bold text-white mb-4 font-serif">Kökur</h1>
        <p className="text-xl text-white font-serif">Við bjóðum upp á fjölbreytt úrval af kökum og tertum.</p>
      </div>
      <ul className="flex flex-wrap justify-center">
        {Kokulist.items.map((braud) => (
          <Vara
            key={braud.id}
            name={braud.name}
            image={getImagePath(braud.images)}
            description={braud.description}
          />
        ))}
      </ul>
    </div>
  );
};

export default Kokur;
