import React from "react";
import PhotoLibrary from "../../components/photolibrary";
import { NavigationLink } from "../../components/navigation_link";
import "./styles.css";

const MyndaAlbum = () => {
  return (
    <div className="flex flex-col items-center justify-center bg-[#C29979]">
      <div className="flex flex-row items-center justify-center bg-black w-[80%] h-24 m-5 relative">
        <div className="absolute left-0 ml-5">
          <NavigationLink title="Til baka" href="/pantanir" />
        </div>
        <h1 className="text-xl text-white font-serif mx-auto md:text-3xl">MYNDA ALBÚM</h1>
      </div>
      <PhotoLibrary />
    </div>
  );
}

export default MyndaAlbum;
