import React from "react";
import "./styles.css";

export const UmOkkur = () => {
  const url = require("../../resources/umokkurbackground.jpeg");
  return (
    <div
      className="px-5 py-12 bg-cover bg-center bg-no-repeat bg-fixed md:px-12 py-24 lg:px-24 lg:py-32 lg:min-h-screen"
      style={{ backgroundImage: `url(${url})` }}
    >
      <div className="flex flex-col items-center justify-center p-4 bg-black/70 md:p-6 lg:p-10">
        <h1 className="font-serif text-white text-3xl mt-3 text-center">
          Fyrirtækið Okkar
        </h1>
        <div className="flex w-full p-2">
          <p className="w-full font-serif text-white text-lg md:text-xl lg:text-2xl text-center">
            Kallabakarí (áður Brauða og kökugerðin) var staðsett á Suðurgötunni á
            Akranesi í rúmlega 50 ár. Það var stofnað árið 1967 af Alfreð Franz
            Karlssyni og síðan rekið af syni hans Karli Ó. Alfreðssyni. Núverandi
            eigendur er þau Alfreð Freyr Karlsson og Valdís Kvaran, þau fluttu
            bakaríið árið 2018 á núverandi stað að Innnesvegi 1 og reka þau
            fyrirtækið með dyggri aðstoð strákana sinna, þeirra Almars Geirs, Karls
            Ívars og Daða Más.
          </p>
        </div>
      </div>
      <div className="um-okkur__right"></div>
    </div>
  );
};

export default UmOkkur;
