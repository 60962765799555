// frontend/components/Súpuplan.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import mexico from '../../resources/supur/mexico.jpeg';
import braudsupa from '../../resources/supur/braudsupa.jpeg';
import aspas from '../../resources/supur/aspas.jpeg';
import tomat from '../../resources/supur/tomat.jpeg';
import sveppa from '../../resources/supur/sveppasupa.jpeg';
import bladlauks from '../../resources/supur/bladlaukssupa.jpg';
import gulrotar from '../../resources/supur/gulrotarsupa.jpg';
import Blómkáls from '../../resources/supur/Blómkáls.jpg';
import brokkoli from '../../resources/supur/brokkoli.jpg';
import tælensk from '../../resources/supur/tælensk.jpg';
import "./styles.css";

const soupImages = {
    Aspassúpa: aspas,
    Blaðlaukssúpa: bladlauks,
    Brauðsúpa: braudsupa,
    'Blómkálssúpa': Blómkáls,
    'Brokkólísúpa': brokkoli,
    'Fiskisúpa': '',
    Gulrótarsúpa: gulrotar,
    'Graskerssúpa': '',
    'Íslensk Kjötsúpa': '',
    'Sætkartöflusúpa': gulrotar,
    "Mexíkósk Kjúklingasúpa": mexico,
    Tómatsúpa: tomat,
    "Rjómalöguð Sveppasúpa": sveppa,
    "Tælensk Núðlusúpa": tælensk
    
};

const monthNames = [
    'Janúar', 'Febrúar', 'Mars', 'Apríl', 'Maí', 'Júní', 'Júlí', 'Ágúst', 'September', 'Október', 'Nóvember', 'Desember'
];

const formatDate = (date) => {
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    return `${day} ${month}`;
}

const handleDate = (soupPlan) => {
    const startDate = new Date(soupPlan.week.startDate);
    const endDate = new Date(soupPlan.week.endDate);
    return `${formatDate(startDate)} - ${formatDate(endDate)}`;
}

export const Súpuplan = () => {
    const [soupPlan, setSoupPlan] = useState({
        Monday: '',
        Tuesday: '',
        Wednesday: '',
        Thursday: '',
        Friday: '',
        week: {
            startDate: '',
            endDate: ''
        }
    });

    useEffect(() => {
        axios.get('https://api.kallabakari.is/api/soupPlan')
            .then(response => {
                setSoupPlan(response.data);
            })
            .catch(error => {
                console.error('Error fetching soup plan:', error);
            });
    }, []);

    const days = ['Mánudagur', 'Þriðjudagur', 'Miðvikudagur', 'Fimmtudagur', 'Föstudagur'];

    return (
        <div className="flex flex-col items-center bg-black p-3 w-full">
            <h2 className="text-2xl md:text-4xl text-white font-serif m-3">Súpuplan</h2>
            <div className="w-1/2 h-0.5 bg-white"></div>
            <p className='text-xl md:text-3xl font-serif text-white m-2'>{handleDate(soupPlan)}</p>
            <div className="flex flex-wrap md:flex-row w-full justify-around">
                {days.map(day => (
                    <div key={day} className="flex flex-col items-center w-[45%] md:w-1/6 p-2">
                        <p className="text-xl md:text-2xl text-white font-serif m-2">{day}</p>
                        <div className="w-2/3 md:w-full h-0.5 bg-white"></div>
                        <img src={soupImages[soupPlan[day]]} alt={soupPlan[day]} className="w-2/3 md:w-full"/>
                        <p className="text-xl text-center md:text-2xl text-white font-serif m-2">{soupPlan[day]}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Súpuplan;
