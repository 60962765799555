import React from 'react';
import { Vara } from '../../components/vara';
import smurtlist from '../../resources/smurt.json';
import './styles.css';

const Smurt= () => {

  const getImagePath = (imageName) => {
    return require(`../../resources/smurt/${imageName}`);
  };

  const url = require('../../resources/cover_samlokur.jpeg');

  return (
    <div className="mx-auto px-4 py-8 bg-cover bg-center bg-no-repeat bg-cover bg-fixed"
    style={{backgroundImage: `url(${url})`}}>
    <div className="text-center py-8 bg-black m-3 rounded-lg p-3">
      <h1 className="text-3xl font-bold text-white mb-4 font-serif">Smurða</h1>
      <p className="text-xl text-white font-serif">Allt bakað og smurt alla morgna</p>
    </div>
    <ul className="flex flex-wrap justify-center">
      {smurtlist.items.map((item) => (
        <Vara
          key={item.id}
          name={item.name}
          image={getImagePath(item.images)}
          description={item.description}
        />
      ))}
    </ul>
  </div>
  );
};

export default Smurt;
