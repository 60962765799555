// CustomAlert.jsx
import React from 'react';
import Modal from 'react-modal';
import './styles.css';

const PontunAlert = ({ isOpen, onClose }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Alert"
            className="custom-modal"
            overlayClassName="custom-overlay"
        >
            <div className="alert-container">
                <h2 className="alert-title">Takk fyrir að panta í gegnum vefsíðuna okkar!</h2>
                <p className="alert-message">Staðfesting verður send í tölvupósti.</p>
                <button onClick={onClose} className="alert-button">Loka</button>
            </div>
        </Modal>
    );
};

export default PontunAlert;
