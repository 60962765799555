import React from "react";
import "./styles.css";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid'; 
import { useState } from "react";
import HafaSambandAlert from "../../components/hafa_samband_alert";
import CustomAlert from "../../components/alert";

export const HafaSamband = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [HafaSamband_alertIsOpen, setHafaSambandAlertIsOpen] = useState(false);
    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            id: uuidv4(),
            name: name,
            phone: phone,
            email: email,
            message: message
        };

        try {
            console.log('Sending message:', data);
            await axios.post('http://localhost:5010/api/comments', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setHafaSambandAlertIsOpen(true);
            setName('');
            setPhone('');
            setEmail('');
            setMessage('');
        }
        catch (error) {
            console.error('Error sending message:', error);
            setAlertIsOpen(true);
            setAlertMessage('Villa kom upp við að senda skilaboð. Vinsamlegast reyndu aftur síðar.');

        }
        

    };



    const url = require('../../resources/pistasiusnudar.jpg');

    return (
        <div className="flex items-center justify-center bg-cover bg-center bg-no-repeat bg-cover bg-fixed"
        style={{backgroundImage: `url(${url})`}}>
            <CustomAlert
                isOpen={alertIsOpen}
                onClose={() => setAlertIsOpen(false)}
                message={alertMessage}
            />
            <HafaSambandAlert
                isOpen={HafaSamband_alertIsOpen}
                onClose={() => setHafaSambandAlertIsOpen(false)}
            />
            <div className="flex flex-col h-[80%] w-[80%] items-center bg-black m-5 rounded-xl md:h-[80%] w-[50%] ">
                <form className="flex flex-col w-full items-center">
                    <div className="flex flex-col items-center m-3">
                        <h1 className="text-3xl text-white font-serif">HAFA SAMBAND</h1>
                    </div>
                    <div className="w-[80%] h-0.5 bg-white"></div>
                    <div className="flex flex-col items-center justify-center w-full m-1 md:flex-row items-center">
                        <div className="flex flex-col items-center w-[80%] m-2 md:w-[39%]">
                            <label htmlFor="name" className="text-xl font-serif mb-1 self-start">NAFN:</label>
                            <input className="w-full h-10" type="text" id="name" name="name" onChange={handleNameChange} required/>
                        </div>
                        <div className="flex flex-col items-center w-[80%] m-2 md:w-[39%]">
                            <label htmlFor="phone" className="text-xl font-serif mb-1 self-start">SÍMANÚMER:</label>
                            <input className="w-full h-10" type="text" id="subject" name="subject" onChange={handlePhoneChange} required/>
                        </div>
                    </div>
                    <div className="flex flex-col items-center w-[80%] m-3">
                        <label htmlFor="email" className="text-xl font-serif mb-1 self-start">NETFANG:</label>
                        <input className="w-full h-10" type="email" id="email" name="email" onChange={handleEmailChange} required/>
                    </div>
                   
                    <div className="flex flex-col items-center w-[80%] m-3">
                        <label htmlFor="subject" className="text-xl font-serif mb-1 self-start">SKILABOÐ:</label>
                        <textarea className="w-full h-20 p-1" id="message" name="message" onChange={handleMessageChange} required/>
                    </div>
                    <div className="flex flex-col w-56 h-12 m-3 bg-yellow-700 border-1 border-white item-center p-2">
                        <button className="text-2xl text-white font-serif" type="submit" onClick={handleSubmit}>SENDA</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default HafaSamband;
