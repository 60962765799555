import React from 'react';
import {
  MDBContainer,
  MDBCol,
  MDBRow,
} from 'mdb-react-ui-kit';
import heiddukaka6 from '../../resources/pontunarkokur/heiddukaka6.jpg';
import marsipan2 from '../../resources/pontunarkokur/marsipan2.JPG';
import heiddukaka2 from '../../resources/pontunarkokur/heiddukaka3.jpg';
import heiddukaka4 from '../../resources/pontunarkokur/heiddukaka4.jpg';
import heiddukaka5 from '../../resources/pontunarkokur/heiddukaka5.jpg';
import heiddukaka7 from '../../resources/pontunarkokur/heiddukaka7.jpg';
import bjossi1 from '../../resources/pontunarkokur/bjossi1.jpg';
import bjossi2 from '../../resources/pontunarkokur/bjossi2.jpg';
import bjossi3 from '../../resources/pontunarkokur/bjossi3.jpg';
import bjossi4 from '../../resources/pontunarkokur/bjossi4.jpg';
import bjossi5 from '../../resources/pontunarkokur/bjossi5.jpg';
import bjossi6 from '../../resources/pontunarkokur/bjossi6.jpg';
import bjossi7 from '../../resources/pontunarkokur/bjossi7.jpg';
import bjossi8 from '../../resources/pontunarkokur/bjossi8.jpg';
import bjossi9 from '../../resources/pontunarkokur/bjossi9.jpg';
import bjossi10 from '../../resources/pontunarkokur/bjossi10.jpg';
import heidrunkaka from '../../resources/pontunarkokur/heidrunkaka.jpeg';
import heidrunkaka2 from '../../resources/pontunarkokur/heidrunkaka2.jpeg';
import afmæliskaka from '../../resources/pontunarkokur/afmæliskaka.jpeg';
import amfæliskaka2 from '../../resources/pontunarkokur/afmælis_minecraft.jpeg';

export default function PhotoLibrary() {
  return (
    <MDBContainer style={{ marginLeft: '20px', marginRight: '20px' }}>
      <MDBRow>
        <MDBCol lg={4} md={6} className='mb-4 mb-lg-0'>
          <img
            src={marsipan2}
            className='w-90 shadow-1-strong rounded mb-4'
            alt='Boat on Calm Water'
          />

          <img
            src={heiddukaka6}
            className='w-90 shadow-1-strong rounded mb-4'
            alt='Wintry Mountain Landscape'
          />

          <img
            src={bjossi4}
            className='w-90 shadow-1-strong rounded mb-4'
            alt='Wintry Mountain Landscape'
          />

          <img
            src={heiddukaka4}
            className='w-100 shadow-1-strong rounded mb-4'
            alt='Wintry Mountain Landscape'
          />

          <img
            src={bjossi5}
            className='w-100 shadow-1-strong rounded mb-4'
            alt='Wintry Mountain Landscape'
          />    

          <img
            src={heiddukaka7}
            className='w-100 shadow-1-strong rounded mb-4'
            alt='Wintry Mountain Landscape'
          />

          <img
            src={bjossi10}
            className='w-100 shadow-1-strong rounded mb-4'
            alt='Wintry Mountain Landscape'
          />
          
        </MDBCol>

        <MDBCol lg={4} md={6}className='mb-4 mb-lg-0'>
          <img
            src={heiddukaka2}
            className='w-100 shadow-1-strong rounded mb-4'
            alt='Mountains in the Clouds'
          />
          <img
            src={afmæliskaka}
            className='w-100 shadow-1-strong rounded mb-4'
            alt='Wintry Mountain Landscape'
          />

          <img
            src={bjossi1}
            className='w-100 shadow-1-strong rounded mb-4'
            alt='Boat on Calm Water'
          />
          <img
            src={bjossi6}
            className='w-100 shadow-1-strong rounded mb-4'
            alt='Wintry Mountain Landscape'
          />
          <img
            src={heiddukaka5}
            className='w-100 shadow-1-strong rounded mb-4'
            alt='Wintry Mountain Landscape'
          />

          <img
            src={bjossi9}
            className='w-100 shadow-1-strong rounded mb-4'
            alt='Wintry Mountain Landscape'
          />

        </MDBCol>

        <MDBCol lg={4} md={6} className='mb-4 mb-lg-0'>
          <img
            src={bjossi3}
            className='w-100 shadow-1-strong rounded mb-4'
            alt='Waves at Sea'
          />

          <img
            src={bjossi2}
            className='w-100 shadow-1-strong rounded mb-4'
            alt='Yosemite National Park'
          />
          <img
            src={heidrunkaka}
            className='w-100 shadow-1-strong rounded mb-4'
            alt='Wintry Mountain Landscape'
          />
          <img
            src={bjossi7}
            className='w-100 shadow-1-strong rounded mb-4'
            alt='Wintry Mountain Landscape'
          />
          <img
            src={amfæliskaka2}
            className='w-100 shadow-1-strong rounded mb-4'
            alt='Wintry Mountain Landscape'
          />
          <img
            src={bjossi8}
            className='w-100 shadow-1-strong rounded mb-4'
            alt='Wintry Mountain Landscape'
          />
          <img
            src={heidrunkaka2}
            className='w-100 shadow-1-strong rounded mb-4'
            alt='Wintry Mountain Landscape'
          />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
