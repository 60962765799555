import React from "react";
import Skinkusalat from "../../resources/salat/skinkusalat.JPG";
import pesto from "../../resources/salat/pesto.JPG";
import ostasalat from "../../resources/ostasalat.JPG";
import rækjusalat from "../../resources/salat/rækjusalat.JPG";
import tunfisk from "../../resources/salat/tunfisk.JPG";
import url from '../../resources/covermynd.jpg';

export const SalotMenu = () => {
    return (
        <div className="flex flex-col bg-black">
            <div className="flex flex-col items-center mb-5 p-3">
                <h2 className="text-4xl text-white font-serif mt-4">Brauðsalötin okkar</h2>
                <p className="text-2xl text-white text-center font-serif">Við bjóðum upp á fjölbreytt úrval af brauðsalötum úr eigin eldhúsi</p>
            </div>
            <div className="flex flex-wrap justify-center items-center bg-cover bg-fixed w-full h-full bg-center bg-no-repeat relative p-5"
                style={{backgroundImage: `url(${url})`}}
            >
                <div className="flex flex-col rounded-lg bg-black border-1 items-center m-2 w-1/3 sm:w-1/4 m-3 md:w-1/4 lg:w-1/6">
                    <h3 className="text-xl md:text-3xl text-white font-serif m-2">Skinkusalat</h3>
                    <img src={Skinkusalat} alt="skinkusalat" className="w-full h-[90%]"/>
                </div>
                <div className="flex flex-col rounded-lg bg-black border-1 items-center m-2 w-1/3 sm:w-1/4 m-3 md:w-1/4 lg:w-1/6">
                    <h3 className="text-xl md:text-3xl text-white font-serif m-2">Döðlupestó</h3>
                    <img src={pesto} alt="pesto" className="w-full h-auto"/>
                </div>
                <div className="flex flex-col rounded-lg bg-black border-1 items-center m-2 w-1/3 sm:w-1/4 m-3 md:w-1/4 lg:w-1/6">
                    <h3 className="text-xl md:text-3xl text-white font-serif m-2">Ostasalat</h3>
                    <img src={ostasalat} alt="ostasalat" className="w-full h-auto"/>
                </div>
                <div className="flex flex-col rounded-lg bg-black border-1 items-center m-2 w-1/3 sm:w-1/4 m-3 md:w-1/4 lg:w-1/6 ">
                    <h3 className="text-xl md:text-3xl text-white font-serif m-2">Rækjusalat</h3>
                    <img src={rækjusalat} alt="rækjusalat" className="w-full h-auto "/>
                </div>
                <div className="flex flex-col rounded-lg bg-black border-1 items-center m-2 w-1/3 sm:w-1/4 m-3 md:w-1/4 lg:w-1/6 ">
                    <h3 className="text-xl md:text-3xl text-white font-serif m-2">Túnfisksalat</h3>
                    <img src={tunfisk} alt="tunfisk" className="w-full h-auto"/>
                </div>
            </div>
        </div>
    );
}

export default SalotMenu;
